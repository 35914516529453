.buy-select-address{
    margin-bottom: 15px;
    .notice-select-address{
        color: @color-grey-dark;
    }
}

.address-list-area{
    background-color: #fff;
    padding: 15px 15px 0;
    .address-item-outer{
        width: 100%;
        margin-bottom: 15px;
        @media (min-width: @screen-md){
            width: 253px;
            padding-right: 0;
        }
        .address-item{
            width: 100%;
            border: 1px solid @color-grey;
            padding: 10px;
            height: 180px;
            float: left;
            @media (min-width: @screen-md){
                margin: 3px;
            }
            &:hover{
                border-color: @color-primary;
            }
            .address-content{
                height: 140px;
                overflow: auto;
            }
            .select-flag{
                position: absolute;
                right: 20px;
                top: 10px;
                color: @color-primary;
                display: none;
                @media (min-width: @screen-md){
                    right: 7px;
                }
            }
        }
    }
    .action-bar{
        clear: both;
        position: absolute;
        bottom: 8px;
    }
    .address-new{
        cursor: pointer;
        font-size: 68px;
        color: #eee;
        text-align: center;
        &:hover{
            color: #ddd;
        }
        .icon{
            font-size: 60px;
            display: block;
            height: 70px;
        }
        .text{
            font-size: 20px;
            display: block;
        }
    }
}
.selected-address-area{
    background-color: #FFF;
    padding: 6px 15px;
    margin-top: 15px;
    @media (min-width: @screen-md){
        margin-left: 16px;
        margin-top: 0;
        width: 360px;
    }
    .select-address{
        border-top: 1px solid @color-grey;
        border-bottom: 1px solid @color-grey;
        padding: 10px 0;
        margin-bottom: 10px;
    }
}