@media (min-width: 992px){
    .container,footer,.wrap,header{
        min-width: 1170px;
    }
}
@media (min-width: 768px){
    .container,footer,.wrap,header{
        min-width: 1170px;
    }
}

a{
    color: @color-default;
    transition: all .3s ease-out;
    &:hover{
        color: @color-default;
        text-decoration: none;
    }
}
hr{
    margin: 12px 0;
}
h1{
    font-size: @font-size-huge;
}
h2{
    font-size: @font-size-large;
}
h3{
    font-size: @font-size-big;
}
h4{
    font-size: 14px;
}

/* bootstrap */
.btn-primary{
    background-color: @color-primary;
    border-color: @color-primary;
    &:hover, &.hover{
        background-color: @color-primary !important;
    }
    &:focus, &.focus, &:active, &.active{
        background-color: @color-primary !important;
        border-color: @color-primary !important;
    }
    &[disabled]{
        background-color: @color-primary !important;
        border-color: @color-primary !important;
    }
}
.btn-danger{
    background-color: @color-danger;
    border-color: @color-danger;
    &:hover, &.hover{
        background-color: @color-danger !important;
    }
    &:focus, &.focus, &:active, &.active{
        background-color: @color-danger !important;
        border-color: @color-danger !important;
    }
    &[disabled]{
        background-color: @color-danger !important;
        border-color: @color-danger !important;
    }
}
.btn-yellow{
    background-color: @color-yellow;
    border-color: @color-yellow;
    &:hover, &.hover{
        background-color: @color-yellow !important;
    }
    &:focus, &.focus, &:active, &.active{
        background-color: @color-yellow !important;
        border-color: @color-yellow !important;
    }
    &[disabled]{
        background-color: @color-yellow !important;
        border-color: @color-yellow !important;
    }
}
.btn-red{
    background-color: @color-red;
    border-color: @color-red;
    &:hover, &.hover{
        background-color: @color-red !important;
    }
    &:focus, &.focus, &:active, &.active{
        background-color: @color-red !important;
        border-color: @color-red !important;
    }
    &[disabled]{
        background-color: @color-red !important;
        border-color: @color-red !important;
    }
}
.text-primary {
    color: @color-primary;
}
.text-danger{
    color: @color-danger;
}
a.text-primary:hover, a.text-primary:focus{
    color: @color-primary !important;
}
.alert{
    margin: 0 auto;
    border-radius: 0;
}
.alert-primary{
    color: @color-primary;
    background-color: @color-primary-slight;
    border-color: @color-primary-light;
}
.well-default{
    background-color: #fff;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.pagination {
    margin: 0 0 20px;
    &>li{
        &>a, &>span{
            color: @color-primary;
        }
    }
    &>.active{
        &>a, &>span, &>a:hover, &>span:hover, &>a:focus, &>span:focus{
            background-color: @color-primary;
            border-color: @color-primary;
        }
    }
}

.container{
    padding-left: 10px;
    padding-right: 10px;
}
.row{
    margin-left: -10px;
    margin-right: -10px;
}
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12{
    padding-left: 10px;
    padding-right: 10px;
}
.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse{
    margin-left: -10px;
    margin-right: -10px;
}

.outline {
    background-color: transparent;
    color: inherit;
    transition: all .25s;
}
.btn-primary.outline {
    color: @color-primary;
}
.btn-success.outline {
    color: #5cb85c;
}
.btn-info.outline {
    color: #5bc0de;
}
.btn-warning.outline {
    color: #f0ad4e;
}
.btn-danger.outline {
    color: #d9534f;
}
.btn-primary.outline:hover,
.btn-success.outline:hover,
.btn-info.outline:hover,
.btn-warning.outline:hover,
.btn-danger.outline:hover {
    color: #fff;
}


.modal-open{
    overflow: auto;
}

body {
    padding-right: 0 !important 
}