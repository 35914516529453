.user-favorite{
    .favorite-list{
        background-color: #fff;
        @media (min-width: @screen-md){
            background-color: transparent;
        }
    }
    .favorite-item{
        background-color: #fff;
        padding: 5px;
        margin-bottom: 30px;
        .product-bottom{
            height: 30px;
            line-height: 30px;
        }
        .product-cover{
            //width: 146px;
            //float: left;
            //margin-right: 5px;
            .thumb-img{
                //max-width: 146px;
                width: 100%;
            }
        }
        .product-info{
            //float: left;
            //width: 242px;
            h3{
                margin: 10px 0;
                font-size: @font-size-normal;
                height: 14px;
                overflow: hidden;
            }
        }
        .product-favorite{
            //float: right;
            //position: relative;
            //top: -42px;
            //padding: 10px 0 0 10px;
            //background-color: #fff;
        }
    }
}