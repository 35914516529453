.brand-view{
	.focus-image{
		width: 100%;
	}
	.focus-area.hidden-xs .brand-info{
		position: absolute;
	    top: 18%;;
	    width: 360px;
	    font-size: 16px;
	    p{
	    	margin-top: 26px;
	    }
	}
    .focus-area.visible-xs{
        .brand-logo{
            width: 120px;
            height: 120px;
            padding-top: 5px;
            float: left;
            img{
                width: 100%;
            }
        }
        .brand-info{
            padding: 5px 10px 10px 130px;
            color: white;
        }
    }
    .relation-posts{
        margin-top: 10px;
        .container{
            background-color: white;
            h3{
                font-size: 30px;
                padding-left: 10px;
                padding-top: 10px;
            }
            .posts-list{
                ul{
                    list-style: none;
                    margin-bottom: 0;
                    padding-left: 0;
                    font-size: large;
                }
                li{
                    margin: 20px 10px;
                }
            }
        }
    }
}

body.brand-detu .wrap{
	background-color: #343434 !important;
}

body.brand-kuner{
    .focus-area{
        color: white;
    }
    .product-item{
        h2{
            font-size: 32px;
        }
        .buy-btn{
            color: white;
        }
    }
}


.brand-theme-ONE{
	.products-area{
		padding-bottom: 10px;
	}
	.product-item{
		margin-top: 20px;
    	background-color: #fff;
    	padding: 10px 0;
        .product-cover{
            img{
                width: 100%;
            }
        }
    	h2{
    		font-size: 56px;
    		margin-top: 46px;
    	}
    	p{
    		font-size: 16px;
    		line-height: 28px;
    	}
    	.product-price{
    	    margin-top: 20px;
    	    font-size: 40px;
    	}
    	.del-original-price{
    		font-size: 26px;
    	}
    	.product-content{
    		padding: 0 40px 15px;
    	}
    	.buy-btn{
    		margin-top: 20px;
    	}
        @media (max-width: @screen-xs){
            margin-top: 0;
            padding: 5px 0 5px;
            margin-bottom: 20px;
            .col-xs-12 img{
                width: 100%;
            }
            h2{
                font-size: 36px;
                margin-top: 20px;
            }
            p{
                font-size: 14px;
                line-height: 24px;
            }
            .product-price{
                margin-top: 0;
                font-size: 30px
            }
            .buy-btn{
                margin-top: 10px;
            }
        }
	}
}