.coupon-item{
	border-radius: 10px;
	background-color: white;
	padding-bottom: 6px;
	margin-bottom: 20px;
	.title{
		text-align: center;
	    font-size: 16px;
	    padding: 6px;
	    background-color: #CCE6B7;
	    border-radius: 10px 10px 0 0;
	    a{
	    	display: block;
		    font-size: 16px;
		    max-width: 250px;
		    text-overflow: ellipsis;
		    height: 46px;
		    overflow: hidden;
	    }
	}
	.discount-value{
		padding: 6px;
		color: #FF5500;
	    text-align: center;
	    font-size: 22px;
	    font-weight: bold;
	    padding-bottom: 0;
	}
	.caption{
		padding: 6px;
		text-align: center;
		.code{
			padding: 5px 16px;
		    background-color: #d0d0d0;
		    border: 1px dashed #808080;
		    display: inline-block;
		}
	}
}