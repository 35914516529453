.order-pay{
    .order-summary{
        padding: 0 15px 15px;
        .table{
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
            td.count{
                min-width: 80px;
                text-align: right;
            }
        }
        .table.product-table{
            margin-bottom: 1px;
        }
        .total-info{
            font-weight: bold;
            overflow: hidden;
            height: auto;
            padding: 0 10px;
            @media (min-width: @screen-md){
                padding: 10px;
            }
        }
    }
    .stripe-button-area{
        line-height: 32px;
        &>form{
            float: left;
            height: 52px;
        }
        .stripe-button-el{
            width: 142px;
        }
    }
    .pay-choice{
        @media (max-width: @screen-xs) {
            border-width: 0;
            border-radius: 0;
            .list-group-item{
                border-radius: 0;
            }
        }
    }
    .stripe-cards{
        width: 180px;
        @media (min-width: @screen-md){
            width: auto;
        }
    }
    
}


    