.product-media-coverage{
    .posts-list{
        .post-item{
            margin-top: 10px;
            .post-logo{
                float: left;
                margin-right: 4px;
            }
            .post-title a{
                text-decoration: underline;
            }
            .post-description{
                color: @color-grey-dark;
            }
        }
    }
}

.product-index{
    .shelf-title{
        font-size: @font-size-normal !important;
        font-weight: bold;
        font-family: "Open Sans",HelveticaNeue,Helvetica,Arial,sans-serif;
    }
    .tag-area{
        background-color: @color-grey-light;
        padding: 0;
        margin-top: 10px;
        .tag-title{
            color: @color-primary;
        }
        .tag-line{
            clear: both;
            margin: 2px 0;
            .tag-title{
                float: left;
                width: 100px;
                font-size: 16px;
                text-align: left;
                line-height: 30px;
                margin-left: 10px;
                @media (min-width: @screen-md){
                    margin-left: 0;
                }
            }
            .tag-items{
                @media (min-width: @screen-md){
                    margin-left: 120px;
                }
                a{
                    display: inline-block;
                    color: @color-default;
                    margin: 5px 5px;
                    padding: 2px 5px;
                }
            }
        }
    }
    .category-area{
        background-color: @color-grey;
        margin-top: 0;
    }
    .summary{
        float: right;
    }
}

.product-view{
    background-color: @color-grey-light;

    .block{
        margin-bottom: 10px;
    }

    .product-bar{
        color: #999;
        margin: 16px 0;
        height: 20px;
        overflow: hidden;
        .title{
            text-overflow: ellipsis;
            width: 180px;
            overflow: hidden;
            white-space: nowrap;
        }
        a{
            color: #999999 !important;
        }
        ul{
            padding-left: 0;
            li{
                display: inline-block;
                float: left;
                margin-right: 12px;
            }
        }
    }

    .focus-area{
        .product-asset{
            background-color: #000;
        }
        .container{
            padding: 10px;
        }
        .asset-link{
            height: 428px;
            position: relative;
            .asset-link-item{
                color: #b2b2b2;
                position: absolute;
                width: 100%;
                img{
                    width: 60px;
                    float: left;
                    margin-right: 10px;
                }
                .content{
                    height: 60px;
                    padding-top: 10px;
                }
                &.sort1{
                    bottom: 10px;
                }
                &.sort2{
                    bottom: 80px;
                }
                &.sort3{
                    bottom: 150px;
                }
            }
        }
    }

    .main-area{
        margin-top: 10px;
    }

    .product-info{
        background-color: #fff;
        margin-top: 5px;
        @media (min-width: @screen-md){
            margin-top: 0;
            width: 480px;
            overflow: hidden;
        }
        h1{
            margin-top: 0;
            margin-bottom: 0;
            font-size: @font-size-large;
        }
        .sub-title{
            color: @color-grey-dark;
        }
        .promotion-text{
            a{
                text-decoration: underline;
                color: @color-primary;
            }
        }
        .info-item{
            font-size: @font-size-normal;
            margin: 10px 0;
            .price{
                font-size: @font-size-large;
            }
            &#cart-add-form {
                #cart-row {
                    position: relative;
                    input[type=number]{
                        float: left;
                        margin-right: 10px;
                    }
                    .add-cart-btn {
                        padding-left: 36px;
                    }
                    .fa-shopping-cart {
                        position: absolute;
                        top: 15px;
                        left: 100px;
                        color: white;
                        font-size: 18px;
                    }
                }
            }
        }
        .text-explain{
            email-subscribe{
                .form-inline{
                    margin-top: -5px;
                }
            }
        }
        .property-item{
            .property-title{
                color: #a9a9a9;
            }
            .property-content{
                display: inline-block;
                .price{
                    font-size: 20px;
                }
            }
        }
        .sku-area{
            .sku-value{
                margin-right: 10px;
                margin-bottom: 10px;
            }
            .sku-item{
            }
        }
        .product-num{
            width: 80px;
            font-size: @font-size-normal;
            display: inline-block;
        }
        .action-block{
            margin: 16px;
        }
        .product-features{
            margin: 40px 0 0;
            @media (min-width: @screen-md){
                margin: 60px 0 0;
            }
            ul{
                color: #949494;
                line-height: 26px;
                padding-left: 24px;
                span{
                    color: #333;
                }
            }
        }
        .shipping-information-area{
            margin-bottom: 10px;
            #freightDefaultContent{
                background-color: white;
                border: 1px solid #ccc;
                &.dropdown-toggle{
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
                .shippingText{
                    float: left;
                }
            }
            .caret{
                margin: 18px 0px auto 15px;
            }
            .dropdown-menu{
                padding: 4px 4px;
                a{
                    padding: 3px 23px 3px 6px !important;
                }
            }
            .divider{
                margin: 4px 0;
            }
            .shipping-to-country{
                margin-bottom: 5px;
            }
            .shipping-to-text{
            }
            .ship-option-tips{
                font-size: @font-size-small;
                padding-left: 12px;
                color: @color-grey-dark;
            }
        }
        .sku-area {
            &.notChooseSku {
                box-shadow: 0 0 10px #D50000;
            }
        }
    }
    .coupon-code-info{
         border: 1px dotted @color-primary;
         padding: 4px 10px;
         i.icon{
            color: @color-primary;
         }
    }

    .product-guarantee{
        padding-bottom: 10px;
        @media (min-width: @screen-md){
            width: 480px;
            overflow: hidden;
        }
        .guarantee-item{
            margin-bottom: 10px;
            .guarantee-img{
                float: left;
                padding-top: 12px;
                img{
                    width: 26px;
                    @media (min-width: @screen-md){
                        width: 36px;
                    }
                }
            }
            .guarantee-content{
                margin-left: 40px;
                @media (min-width: @screen-md){
                    margin-left: 48px;
                }
                .guarantee-title{
                    font-size: 16px;
                }
                .guarantee-desc{
                    font-size: 14px;
                    color: #9f9f9f;
                }
            }
        }
    }

    .promotion-message{
        margin-bottom: 0;
    }

    .product-consumer-reviews-count-text{
        display: inline-block;
        margin-left: 10px;
    }

    .shipping-information-area{
        .freight-line{
            line-height: 24px;
            font-size: 14px;
            text-align: left;
            .country{
                display: inline-block;
                margin-right: 20px;
            }
            .money{
                display: inline-block;
            }
        }
        .estimated-time, .shipping-information-icon, .message{
            color: #999;
            font-size: 12px;
            text-align: left;
            @media (min-width: @screen-md){
                font-size: 13px;
            }
        }
        .shipping-information-icon{
            cursor: pointer;
        }
    }

    .format-layout {
        @media (min-width: @screen-md){
            width: 670px;
            float: left;
            margin-right: 10px;
        }
        @media (max-width: @screen-xs){
            display: none;
        }
    }

    .product-detail{
        background-color: #fff;
        padding: 0 20px;

        @media (min-width: @screen-md){
            width: 670px;
            float: left;
            margin-right: 10px;
        }

        margin-bottom: 20px;
        position: relative;
        a{
            color: @color-primary;
        }
        .line{
            margin-top: 10px;
            margin-bottom: -1px;
            border-bottom: 2px solid #53a318;
            line-height: 32px;
            display: inline-block;
            padding-right: 20px;
            font-size: @font-size-big;
        }
        h2{
            border-bottom: 2px solid @color-primary;
            line-height: 40px;
            display: inline-block;
            margin-bottom: 0;
            position: relative;
            top: 1px;
            padding-right: 20px
        }
        .product-information{
            padding-top: 10px;
            .compare-table-wrap{
                width: 100%;
                overflow-x: scroll;
            }
            .product-scene{
                img{
                    width: 100%;
                }
                p{
                    height: 80px;
                    margin-top: 5px;
                    overflow: hidden;
                }
                iframe{
                    width: 100%;
                }
            }
            .product-suit{
                padding-top: 10px;
                @media (min-width: @screen-md){
                    padding-left: 10px;
                }
                .product-suit-one{
                    display: inline-block;
                    border: 1px solid #eee;
                    padding-bottom: 15px;
                    @media (min-width: @screen-md){
                        margin-right: 15px;
                    }
                    &:hover{
                        border: 1px solid @color-primary;
                        .title{
                            color: @color-primary;
                        }
                    }
                    .image{
                        display: block;
                        img{
                            width: 100%;
                        }
                    }
                    .title{
                        display: block;
                        text-align: center;
                        color: #999;
                        height: 40px;
                        overflow: hidden;
                    }
                }
            }
            .additional-content img{
                @media (max-width: @screen-xs){
                    width: 100%;
                }
            }
        }
        .product-params{
            padding-top: 20px;
            table th{
                background-color: #f3f3f3;
                font-weight: normal;
            }
        }
        .product-manufacturer{
            padding-top: 20px;
            img{
                width: 100%;
            }
            h5{
                margin-top: 14px;
            }
            p{
                height: 60px;
                display: block;
                overflow: hidden;
            }
        }
        .product-video-content{
            padding-top: 20px;
            iframe{
                margin-right: 12px;
                @media (max-width: @screen-xs) {
                    width: 100%;;
                }
            }
        }
        .product-detail-content.collapseDetail {
            height: 400px;
            overflow: hidden;
        }
        #expand-detail {
            position: absolute;
            top: 365px;
            left: 0;
            width: 100%;
            background: #fff;
            padding: 0px 0 15px;
            color: #4978b3;
            cursor: pointer;
            text-align: center;
        }
        #expand-detail:before {
            content: '';
            position: absolute;
            bottom: 100%;
            width: 100%;
            height: 100px;
            left: 0;
            background: -webkit-gradient(linear, 0 0, 0 bottom, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 1)));
        }
        #collapse-detail {
            width: 100%;
            color: #4978b3;
            background: #fff;
            cursor: pointer;
            text-align: center;
            display: block;
            padding-bottom: 15px;
        }
    }

    .product-v1-detail{
        background-color: #fff;
        margin-top: 30px;
        @media (min-width: @screen-md){
            padding: 30px;
        }
        img{
            width: 100%;
        }
    }

    #product-shows{
        padding: 0;
        margin-right: 0;
        @media (min-width: @screen-md){
            padding: 10px 10px 0 10px;
            margin-right: 10px;
            width: 670px;
            float: left;
        }
    }
    #product-carousel {
        width: 100%;
        @media (min-width: @screen-md){
            width: 650px;
        }
        .carousel-inner{
            .item{
                img{
                    height: auto;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 100%;
                    @media (min-width: @screen-md){
                        width: 650px;
                        height: 650px;
                        background-color: #f4f4f4;
                    }
                }
            }
            .display-count {
                position: absolute;
                right: 10px;
                top: 1px;
                font-size: 20px;
                color: #fff;
                text-shadow: 0px 0px 2px #000;
            }
        }
    }

    ul.thumbnails-carousel {
        padding: 5px 0 0 0;
        margin: 0;
        list-style-type: none;
        text-align: center;
        li{
            width: 60px;
            height: 60px;
            margin-right: 5px;
            margin-bottom: 5px;
            float: left;
            cursor: pointer;
            img{
                height: 60px;
                background-position: center;
                background-repeat: no-repeat;
                background-color: #f4f4f4;
                background-size: 60px;
            }
        }
    }
    ul.thumbnails-carousel .center {
        display: inline-block;
    }
    .controls-background-reset {
        background: none !important;
        bottom: 150px;
        top: 150px;
    }
    .active-thumbnail {
        opacity: 0.4;
    }
    .indicators-fix {
        bottom: 70px;
    }

    .detail-nav-bar{
        padding-left: 230px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    #product-detail{
        width: 670px;
        img{
            width: 100%;
        }
    }

    h1.product-title{
        margin-top: 0;
        font-size: 22px;
    }

    .recommend-area{
        margin-top: 46px;
        .product-recommend{
            width: 260px;
            margin-top: 16px;
        }
    }

    .product-anchors{
        a{
            cursor: pointer;
        }
    }

    .content-block{
        margin-top: 16px;
    }
}

.product-index{
    .product-area{
        background-color: @color-grey-light;
        .list-summary, .empty{
            background-color: @color-grey-weight;
            padding: 16px 0;
        }
        .list-summary{
            .summary-title{
                float: left;
                h1{
                margin: 0;
    font-size: 20px;
                }
            }
        }
        .list-products{
            padding: 20px 0 0;
        }
    }
}

.product-section{
    margin-top: 20px;
    background-color: #fff;
    padding: 15px 20px 0;
    .title{
        padding-right: 20px;
        font-size: @font-size-big;
        .interest-title{
            font-size: @font-size-big;
        }
    }
    .reviews-list{
    }
    .thumbnail{
        border: 1px solid #fff;
        margin-bottom: 0;
    }
}

.product-group{
    @media (min-width: @screen-md){
        width: 480px;
        overflow: hidden;
        padding-bottom: 6px;
    }
    .group-item-list{
        padding-top: 10px;
        .thumbnail{
            margin-bottom: 0;
            .product-cover{
                overflow: hidden;
                display: block;
            }
            .product-title{
                max-height: 38px;
                height: auto;
                white-space: normal !important;
            }
        }
        .original-price, .real-price{
            display: block;
            @media (min-width: @screen-md){
                display: inline;
            }
        }
    }
}


.product-context{
    background-color: @color-grey;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 10px 0;
    .thumb{
        img{
            width: 100%;
        }
    }
    .center{
        max-height: 200px;
        overflow: hidden;
        padding-left: 24px;
        h3 {
            margin-top: 10px;
        }
    }
    .right{
        a.btn{
            width: 128px;
            margin-left: 28px;
        }
    }
}

.sku-item-line{
    margin-top: 0;
}


.product-reviews, .user-product-review{
    .review-item{
        margin-top: 14px;
    }
    .review-info{
        font-size: 13px;
        display: inline-block;
        margin-left: 10px;
    }
    .review-content{
        margin-top: 4px;
    }
    .product-reviews-more{
        margin-top: 15px;
    }
}

.product-reviews-page{
    .product-simple{
        .product-info{
            margin-top: 15px;
            .name{
                margin-top: 0;
            }
            .price-area{
                font-size: 18px;
                display: inline-block;
                margin-left: 10px;
            }
            .rate-price{
                margin-top: 15px;
            }
            .link{
                margin-top: 15px;
            }
        }
    }
    .list-pager{
        margin-top: 15px;
        .pagination{
            margin-bottom: 0;
        }
    }
    .list-summary{
        margin-top: 15px;
    }
}

.product-simple{
    margin-top: 20px;
    h2.name{
        font-size: 18px;
        margin-bottom: 5px;
        @media (min-width: @screen-md){
            font-size: 22px;
            margin-bottom: 10px;
        }
    }
    .rate-price{
        margin-top: 10px !important;
        @media (min-width: @screen-md){
            margin-top: 15px;
        }
    }
    .price-area{
        font-size: 18px;
        display: inline-block;
        margin-left: 0 !important;
        @media (min-width: @screen-md){
            margin-left: 10px;
        }
    }
    .link{
        margin-top: 10px !important;
        @media (min-width: @screen-md){
            margin-top: 15px;
        }
    }
}

.product-free-trial{
    .product-free-trial-detail{
        p{
            margin-top: 15px;
            line-height: 32px;
        }
    }
}

.product-faq{
    padding-top: 10px;
    padding-bottom: 15px;
    ol{
        padding-left: 18px;
    }
    li{
        margin: 15px 0;
        strong{
            display: inline-block;
            margin-bottom: 5px;
        }
    }
}

.add-question {
    padding-bottom: 30px;
    .submit-area {
        margin-top: 10px;
        .email-input {
            width: 40%;
            display: inline-block;
        }
        .submit-question {
            float: right;
        }
    }
}

.product-follow-bar-wrap{
    display: none;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 3px 4px rgba(0,0,0,0.18);
    transition: .15s ease opacity;
    z-index: 1000;
    background: #fff;
    height: 55px;
    @media (min-width: @screen-md){
        height: 60px;
    }
    .product-follow-bar{
        margin: 10px;
        @media (min-width: @screen-md){
            width: 1140px;
            padding: 8px;
            margin: 0 auto;
            .site-branch{
                font-size: 14px;
                padding-top: 6px;
            }
            .add-cart-button{
                padding-top: 8px;
            }
            .product-info{
                margin-right: 4px;
                text-align: right;
                padding-top: 12px;
                margin-right: 20px;
                &.product-info-bar {
                    width: 510px;
                }
                .product-name{
                    display: inline-block;
                    font-size: @font-size-big;
                    width: 412px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    float: left;
                }
                .product-price{
                    display: inline-block;
                    font-size: 16px;
                    padding-top: 2px;
                }
            }
        }
        .site-branch{
            float: left;
            font-weight: bold;
        }
        .add-cart-button{
            float: right;
        }
        .product-info{
            float: right;
            .product-price{
                margin-left: 6px;
            }
        }
        .product-follow-bar-logo{
            width: 162px;
            size: 35px;
        }
    }
}


.product-thumbnail{
    .thumbnail{
        padding: 0;
        border-radius: 0;
        border-width: 0;
        overflow: hidden;
        margin-bottom: 10px;
        position: relative;
        @media (min-width: @screen-md){
            margin-bottom: 20px;
        }
        img{
            width: 100%;
        }
        .discount{
            position: absolute;
            top: 10px;
            left: 10px;
            background: #FF5500;
            color: white;
            padding: 0 6px;
            border-radius: 2px;
            line-height: 18px;
            font-weight: 600;
            font-size: 8px;
        }
        .deal-week{
            position: absolute;
            top: 10px;
            left: 10px;
        }
        .caption{
            text-align: center;
            padding: 8px 0 0;
            height: 68px;
            @media (min-width: @screen-md){
                height: auto;
            }
            a.product-title{
                color: @color-default;
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                font-size: 14px;
                max-width: 100%;
                line-height: 20px;
                max-height: 2.8em;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                @media (min-width: @screen-md){
                    display: inline-block;
                    white-space: nowrap;
                    font-size: 16px;
                    max-width: 360px;
                    padding: 0 5px;
                }
            }
            .count-down{
                margin-bottom: 10px;
            }
            .product-category{
                color: #cacaca;
                padding-left: 10px;
                font-size: 16px;
                &:hover{
                    text-decoration: underline;
                }
            }
            .choice-description{
                blockquote{
                    position: relative;
                    margin: 0 10px;
                    border: none;
                    font-size: 14px;
                    padding: 0 20px 10px 20px;
                    &:before{
                        position: absolute;
                        font-size: 4em;
                        line-height: 1;
                        top: 0;
                        left: 0;
                        content: "\201C";
                    }
                    &:after{
                        position: absolute;
                        float: right;
                        font-size: 4em;
                        line-height: 1;
                        right: 0;
                        content: "\201D";
                    }
                }
            }
        }
        .price{
            clear: both;
            font-size: @font-size-big;
            @media (min-width: @screen-md){
                margin-bottom: 8px;
            }
            .real-price{
                color: @color-primary;
            }
            .original-price{
                color: @color-grey-dark;
            }
        }
        .flag{
            position: absolute;
            right: 0;
            display: inline-block;
            float: right;
            width: 52px;
        }
    }
    &.onSale{
        .thumbnail{
            .product-title{
                max-width: 272px !important;
            }
        }
    }
    &.relation .caption{
        height: auto;
        padding: 5px 0 0;
        a.product-title{
            font-size: small;
            max-height: 35px;
            height: auto;
        }
        .price{
            font-size: @font-size-normal;
        }
    }

    .thumbnail-link{
        position: relative;
        display: block;
        overflow: hidden;
        .product-created-at{
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.2);
            color: white;
            padding: 5px 8px;
            border-radius: 5px;
        }
        .choice-level{
            position: absolute;
            left: 10px;
            bottom: 10px;
            font-size: 22px;
            color: yellow;
            i{
                padding-right: 4px;
            }
        }
    }
}