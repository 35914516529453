.buy-product{
    padding-top: 40px;
    .message{
        border-color: @color-grey-weight;
        background-color: #fff;
        border: 1px solid @color-grey;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        margin: 0;
        .add-success{
            font-size: @font-size-large;
            padding: 15px;
            border-right: 1px solid @color-grey;
            text-align: center;
            .product-thumb{
                width: 62px;
                height: 62px;
            }
            @media (min-width: @screen-md){
                width: 300px;
                text-align: left;
            }
        }
        .cart-info{
            padding: 15px;
            text-align: center;
            font-size: @font-size-large;
            @media (min-width: @screen-md){
                margin-top: 18px;
            }
        }
        .cart-action{
            padding: 18px 0;
            text-align: center;
            @media (min-width: @screen-md){
                padding: 28px 0;
            }
            .cart-to-checkout{
                display: inline-block;
                margin-top: 16px;
                @media (min-width: @screen-md){
                    display: inline;
                    margin-top: 0;
                }
            }
        }
    }
    .recommend-products{
        margin-top: 60px;
        .row{
            margin-top: 15px;
        }
    }
}
.buy-cart{
    .cart-table{
        .cart-line{
            border-bottom: 1px solid @color-grey-light;
            height: auto;
            overflow: hidden;
            .product-info{
                display: inline-block;
                width: 450px;
            }
            .unit-price{
                display: inline-block;
                width: 140px;
                text-align: center;
            }
            .quantity{
                display: inline-block;
                width: 100px;
                text-align: center;
            }
            .sub-price{
                display: inline-block;
                width: 140px;
                text-align: center;
            }
            .action{
                display: inline-block;
                width: 120px;
                text-align: center;
                span{
                    color: @color-grey-dark;
                    cursor: pointer;
                }
            }
        }
        .cart-content{
            .product-info{
                padding: 10px 0;
                .product-thumb{
                    width: 94px;
                    height: 94px;
                    float: left;
                    margin-right: 10px;
                }
            }
            .unit-price{
                padding: 16px 0;
                vertical-align: top;
            }
            .quantity{
                vertical-align: top;
                padding: 0 0 10px 0;
                @media (min-width: @screen-md){
                    padding: 10px 0;
                }
            }
            .sub-price{
                padding: 16px 0;
                vertical-align: top;
            }
            .action{
                padding: 0 0 10px 0;
                float: right;
                vertical-align: top;
                @media (min-width: @screen-md){
                    padding: 10px 0;
                    float: none;
                }
            }
        }
        .cart-title{
            padding: 15px 0 10px;
        }
    }
    .cart-sub-total{
        font-size: 22px;
    }
    .cart-products-area{
        background-color: #FFFFFF;
    }
    .cart-total-area{
        background-color: #FFFFFF;
        padding: 20px 15px;
        margin-top: 15px;
        margin-bottom: 20px;
        @media (min-width: @screen-md){
            margin-top: 10px;
        }
    }
}
.buy-checkout{
    .block-primary{
        h2.block-title{
            margin: 0;
        }
        .block-description{
            margin-top: 8px;
            color: @color-grey-dark;
        }

        &.customer{
            input[type=email]{
                width: 300px;
            }
        }
        
        hr.divide{
            width: 100px;
            border-width: 4px;
        }
        .address-area{
            margin-bottom: 10px;
        }
    }
    
    .summary{
        .summary-table{
            margin-top: 10px;
            border-bottom: 1px solid #fff;
            margin-bottom: 10px;
            td{
                border-top: 1px solid #fff !important;
            }
        }
        .item-title{
            color: @color-grey-dark;
        }
        .total-money{
            font-weight: bold;
            font-size: 18px;
        }
        .summary-divide{
        }
    }
    .delivery-speed{
        margin-top: 16px;
        padding: 1px 15px;
        @media (min-width: @screen-md){
            float: right;
            width: 360px;
        }
        .delivery-speed-table{
            margin-bottom: 10px;
        }
    }
    .product-thumb{
        width: 70px;
        height: 70px;
        float: left;
        margin-right: 10px;
    }
    .list-group-item-heading{
        label{
            margin-bottom: 0;
        }
        input[name=shipping-rate-type]{
            margin-top: 0;
        }
        span{
            top: -2px;
            position: relative;
        }
    }
    .coupon-code{
        padding: 1px 16px 8px;
        margin-top: 16px;
        .coupon-form{
            border-top: 1px solid #EDEDED;
            padding: 10px 0;
            height: 68px;
            @media (min-width: @screen-md){
                height: 56px;
            }
            .input-group{
                width: 280px;
            }
            .message{
                color: #9f9f9f;
                margin: 6px 0;
                @media (min-width: @screen-md){
                    margin: 6px 16px;
                }
            }
        }
    }
    .order-leave-message{
        padding: 1px 16px 8px;
        margin-top: 16px;
        .content{
            border-top: 1px solid #EDEDED;
            padding: 10px 0;
            height: 68px;
            @media (min-width: @screen-md){
                height: 56px;
            }
        }
    }
    .order-lucky-bag-notice{
        padding: 15px;
        margin-top: 20px;
        display: none;
        &.show {
            display: block;
        }
        a{
            text-decoration: underline;
        }
    }
    .discount-freight-info{
        margin-top: 16px;
        padding: 8px;
    }
}