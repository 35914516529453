body{
    font-family: "Open Sans",HelveticaNeue,Helvetica,Arial,sans-serif;
    font-size: 14px;
}
h1,h1,h3,h4,h5,h6{
    font-family: HelveticaNeue,Helvetica,Arial,sans-serif;
}
h3{
    margin-top: 10px;
}

.wrap{
    background-color: @color-grey-light;
}

.clear{
    clear: both;
}
.block{
    background-color: #FFFFFF;
    padding: 20px;
    margin-bottom: 20px;
    &.thoughts {
        text-align: left;
    }
    .title{
        font-size: @font-size-big;
    }
}

.modal-outer{
    padding-top: 40px;
}
.content-modal{
    background-color: #fff;
    padding: 0;
    box-shadow: rgba(0,0,0,.27) 0 1px 3px,#DCDCDC 0 1px 0,#DDD 0 -1px 0;
    .content-modal-header{
        border-bottom: 1px solid #CFCFCF;
        padding: 18px 30px;
        box-shadow: rgba(0,0,0,0) 0 1px 8px;
        background: url('/img/box-title-bg.jpg') repeat-x;
        height: 60px;
        h1{
            margin: 0;
            font-size: @font-size-large;
        }
    }
    .content-modal-body{
        padding: 30px;
    }
    .modal-code{
        font-size: 100px;
    }
}
.contact-container {
    background: none;
    box-shadow: none;
    .leave-message-box {
        background: #fff;
        padding: 0;
        box-shadow: rgba(0,0,0,0.27) 0 1px 3px, #DCDCDC 0 1px 0, #DDD 0 -1px 0;
    }
    .our-information {
        background: #fff;
        margin-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 30%;
        box-shadow: rgba(0,0,0,0.27) 0 1px 3px, #DCDCDC 0 1px 0, #DDD 0 -1px 0;
    }
}
.content-modal.size-small{
    @media (min-width: @screen-md){
        width: 480px;
    }
    .content-modal-body{
        padding: 30px;
    }
}
.content-modal.modal-info{
    text-align: center;
}


a.hover-primary:hover{
    color: @color-primary;
    text-decoration: underline;
}


.h1-title{
    clear: both;
    margin-top: 15px;
    border-bottom: 1px solid @color-grey-weight;
    h1{
        display: inline-block;
        margin-bottom: 0;
        padding-bottom: 10px;
    }
    .more{
        float: right;
        display: block;
        font-size: 22px;
        color: @color-grey-weight;
        text-decoration: none;
        margin-top: 24px;
        &:hover{
            color: @color-grey-dark;
        }
    }
}

nav, nav .container{
    background-color: #1A1713;
}
.navbar-brand{
    margin: 21px 0 0 10px;
    height: 16px;
    padding: 0;
    img {
        width: 120px;
    }
    @media (min-width: @screen-md){
        margin: 18px 0 0 0;
        padding: 0 15px 0;
    }
}
.navbar-inverse{
    background-color: #1A1713;
    border-color: none;
    .navbar-toggle {
        margin-top: 14px;
        float: left;
        margin-left: 12px;
        margin-right: 0;
    }
}
.navbar-black{
    border-radius: 0;
    margin-bottom: 0;
    z-index: 100;
    opacity:0.95;
    filter:alpha(opacity=80);
    height: 60px;
    border-width: 0;
    a{
        color: white;
        line-height: 30px !important;
    }
    .navbar-right > li:hover{
        background-color: #5F5F5F !important;
    }
    .navbar-right li.dropdown{
        @media (min-width: @screen-md){
            width: 80px;
            text-align: center;
        }
    }
    .dropdown.open{
        a{
            color: white;
        }
        &>a{
            background-color: #5F5F5F !important;
        }
        &>ul{
            background-color: #000 !important;
        }
    }
    .dropdown-menu{
        a:hover{
            background-color: #5F5F5F !important;
        }
        .divider{
            background-color: #5F5F5F;
        }
    }
}

body.page .wrap{
    background: #fff;
}

.page-title{
    padding-top: 10px;
    padding-bottom: 10px;
}

.box-inverse{
    background-color: @color-grey-light;
    padding-top: 20px;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.page-message{
    width: 620px;
}

.navbar-black a{
    background-color: transparent !important;
}

/* box shadow */
.box-shadow{
    &:hover, &.active{
        //-webkit-box-shadow: 0px 0px 8px 1.5px rgba(0, 0, 0, .15), 0px 0px 70px 8px rgba(0, 0, 0, 0.15);
        //-moz-box-shadow: 0px 0px 8px 1.5px rgba(0, 0, 0, .15), 0px 0px 70px 8px rgba(0, 0, 0, 0.15);
        //-ms-box-shadow: 0px 0px 8px 1.5px rgba(0, 0, 0, .15), 0px 0px 70px 8px rgba(0, 0, 0, 0.15);
        //box-shadow: 0px 0px 8px 1.5px rgba(0, 0, 0, .15), 0px 0px 70px 8px rgba(0, 0, 0, 0.15);
        //border-radius: 5px;
        box-shadow: 0 15px 30px rgba(0,0,0,0.1);
        transition: all .2s linear;
    }
}

.box-shadow-go{
    box-shadow: 0 15px 30px rgba(0,0,0,0.1);
    transition: all .2s linear;
    transform: translate3d(0, -2px, 0);
}
.box-shadow-back{
    transition: all .2s linear;
    transform: translate3d(0, 0, 0);
}

.text-shadow{
    &:hover, &.active{
        background: rgba(255,255,255,1);
        -webkit-box-shadow: 0px 0px 8px 1.5px rgba(0, 0, 0, .15), 0px 0px 70px 8px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 0px 8px 1.5px rgba(0, 0, 0, .15), 0px 0px 70px 8px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 0px 0px 8px 1.5px rgba(0, 0, 0, .15), 0px 0px 70px 8px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 8px 1.5px rgba(0, 0, 0, .15), 0px 0px 70px 8px rgba(0, 0, 0, 0.15);
        transition: all .3s ease-out;
        border-radius: 5px;
    }
}

.text-highlight{
    &:hover, &.active{
        background-color: #fff;
        border-radius: 5px;
    }
}

.box-default{
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
}

.body-content > .alert{
    text-align: center;
}
.wrap > .alert{
    text-align: center;
}

.text-primary{
    color: @color-primary;
}
.text-explain{
    color: @color-danger;
}
.text-grey{
    color: @color-grey-dark;
}

.label-primary{
    background-color: @color-primary;
    padding-bottom: .2em;
}

a.primary-link{
    text-decoration: underline;
    color: @color-primary;
}
a.text-decoration{
    text-decoration: underline !important;
}

/* New */
.block-primary{
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
}

.thumb-clear{
    .caption{
        text-align: left !important;
        padding: 10px !important;
        .product-title{
            font-size: 16px !important;
            height: auto !important;
        }
        .price{
            display: inline-block;
            margin-top: 5px;
        }
    }
    .checkout-btn{
        float: right;
    }
}

.part-left{
    padding-left: 0;
    padding-right: 0;
    @media (min-width: @screen-md){
        padding-left: 15px;
        padding-right: 15px;
    }
}

.rating-xxs{
    font-size: 1.2em !important;
}

#global-message{
    background: #fb9f48;
    color: #fff;
    padding: 4px 0;
}

#dialog-subecribe{
    height: 200px;
    top: auto;
    bottom: 100px;
    color: #333;
    @media (min-width: @screen-md){
        .modal-body{
            height: 136px;
        }
        .icon{
            float: left;
            height: 87px;
            margin-right: 20px;
        }
    }
    @media (max-width: @screen-xs){
        height: auto;
        bottom: 36px;
        .modal-body{
            padding: 5px;
        }
        .icon{
            text-align: center;
            margin-bottom: 20px;
            display: none;
        }
        .text{
            text-align: center;
            h4{
                line-height: 22px;
            }
        }
    }
}

.the-bo-content{
    color: #fff;
    a{
        color: #fff;
    }
}
.the-show-content{
    clear: both;
    padding-top: 10px;
    img{
        display: none;
    }
}
//.pre-sale-btn {
//    color: #fff;
//    background-color: #4c8622;
//}
.count-down{
    font-size: small;
}

.count-badge {
     background: @color-primary;
     width: 22px;
     height: 22px;
     text-align: center;
     color: #fff;
     top: 10px;
     right: 0;
     border-radius: 12px;
     position: absolute;
    span {
        display: block;
        position: relative;
        font-size: 12px;
        font-weight: bold;
        width: 100%;
        line-height: 22px;
        text-align: center;
        z-index: 1;
    }
}


#global-nav{

    input[name=keywords]{
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    form >.input-group{
        background-color: white;
    }

    #nav-product-search-form{
        width: 290px;
        display: inline-block;
        margin-top: 13px;
        input[name=keywords]{
            border: none;
            border-radius: 0;
            width: 260px;
            &:focus{
                box-shadow: none;
            }
        }
        button[type=submit]{
            border: none;
            border-radius: 0;
            padding-top: 3px;
            font-size: 18px;
            padding-bottom: 5px;
        }
    }

    .navbar-right{
        color: #FFF;
        a{
            font-size: 14px;
            color: #b2b2b2;
            padding-left: 16px;
            padding-right: 16px;
        }
        a.icon{
            font-size: 22px;
            padding-left: 18px;
            padding-right: 18px;
        }
        .dropdown a{
            color: #fff;
        }
        li{
            position: relative;
            list-style-type: none;
            .dropdown{
                visibility: hidden;
                display: flex;
                position: absolute;
                background: #fff;
                opacity: 0;
                z-index: 1;
                top: 100%;
                left: -275px;
                border: 1px #e5e5e5 solid;
                box-shadow: 0 1px 3px #ddd;
                padding: 10px;
                transition: opacity .1s;
                ul{
                    padding: 0 10px 0 10px;
                    li{
                        display: flex;
                        padding: 0;
                        vertical-align: baseline;
                        a{
                            font-size: 15px;
                            text-decoration: none;
                            white-space: nowrap;
                            color: #777;
                            padding-left: 0;
                            padding-right: 0;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            &:hover{
                .dropdown{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .navbar-header{
        .logo-outer{
            width: 200px;
            margin-top: 12px;
            margin-left: 15px;
            height: 38px;
            overflow: hidden;
            display: inline-block;
            margin-bottom: 0;
            a{
                color: rgba(0,0,0,0);
                background-repeat: no-repeat;
                display: block;
            }
        }
    }

    .mobile-cart{
        float: right;
        margin-top: 14px;
        margin-right: 12px;
        border-color: #333;
        font-size: 18px;
        padding: 1px 11px;
    }
}

.subscribe-block{
    @media (min-width: @screen-md){
        width: 480px;
        overflow: hidden;
    }
    .icon{
        padding-top: 26px;
        img{
            width: 86px;
        }
        @media (min-width: @screen-md){
            padding-top: 12px;
            img{
                width: 108px;
            }
        }
    }
    .context{
        text-align: left;
    }
    .action-area .input-group{
        width: auto;
        @media (min-width: @screen-md){
            width: 300px;
        }
    }
}

.dialog-retain{
    .item-block{
        clear: both;
        width: 620px;
        padding: 10px 10px 10px 20px;
        margin-bottom: 10px;
        background-color: white;
    }
    .item-icon {
        display: inline-block;
        font-size: 44px;
        color: green;
        width: 40px;
        color: #79bf45;
    }
    .item-content {
        display: inline-block;
        margin-left: 20px;
        width: 360px;
        .item-title{
            font-size: 22px;
        }
        .item-desc {
            font-size: 16px;
            color: gray;
        }
    }
    .item-cta {
        display: inline-block;
        padding-top: 15px;
        width: 150px;
        float: right;
    }
}

#closeShowModal {
    .modal-close-button {
        width: 26px;
        height: 26px;
        border-radius: 13px;
        border: 1px solid;
        margin-right: -2px;
        margin-top: -2px;
    }
}