.giveaway{

	/* gleam style */
	h1.title-giveaway-active{
		margin: 10px auto 20px;
		text-align: center;
	}
	h2.title-giveaway-last{
		margin: 40px auto 20px;
		text-align: center;
	}



	.hero-title{
		margin-top: 20px;
		text-align: center;
		margin-bottom: 20px;
		i{
			color: @color-primary;
			font-size: 60px;
		}
	}

	.giveaway-content{
		background-color: white;
    	margin: 0 auto 20px;
    	padding: 20px;
    	@media (min-width: @screen-md){
	        width: 800px;
	    }
	}

	.giveaway-subscribe{
		background-color: white;
		margin: 0 auto 20px;
		padding: 20px;
		font-size: 24px;
		@media (min-width: @screen-md){
	        width: 800px;
	        h3{
	        	font-size: 30px;
	        }
	        .subscribe-form{
				.action-area{
					width: 732px;
		    		text-align: center;
		    		&>.input-group{
		    			margin-top: 26px;
		    			input[type=email]{
						    height: 46px;
						    width: 360px;
		    			}
		    		}
		    		button[type=button]{
		    			height: 46px;
		    		}
				}
			} 
	    }
	}

	.icon{
		color: @color-primary;
	}

	.icon.col-sm-3{
		display: none;
	}
	.context .text{
		display: none;
	}

	ul.countdown {
		padding: 0;
		text-align: center;
	    margin-bottom: 28px;
		li {
			display: inline-block;
			&.seperator {
				font-size: 40px;
				line-height: 70px;
				vertical-align: top;
			}
			span {
				font-size: 40px;
				font-weight: 300;
				line-height: 80px;
			}
			p {
				color: #a7abb1;
				font-size: 14px;
			}
		}
	}

	.number-date{
		font-size: 22px;
	    color: gray;
	    margin-bottom: 35px;
	    padding-left: 45px;
	}
}