.special-body .wrap{
    padding: 20px 0 60px;
}

.special-item, .idea-item{
    overflow: hidden;
    text-align: left;
    height: 342px;
    margin-bottom: 20px;
    .cover-image{
        img{
            width: 100%;
        }
    }
    .caption{
        background-color: #fff;
        padding: 10px;
        height: 170px;
        h3{
            margin-top: 0;
        }
        .desc{
            height: 40px;
            overflow: hidden;
            color: @color-grey-dark;
        }
        p{
            margin-bottom: 0;
        }
    }
    .action{
        line-height: 30px;
    }
}

.special-view{
    iframe{
        @media (max-width: @screen-xs){
            width: 100%;
        }
    }
    .desc{
        background-color: #fff;
        padding: 20px;
        margin: 40px 0;
        &:before{
            content: "\f10d";
            font: normal normal normal 14px/1 FontAwesome;
            width: 26px;
            height: 24px;
            float: left;
            color: @color-grey-dark;
        }
        &:after{
            content: "\f10e";
            font: normal normal normal 14px/1 FontAwesome;
            width: 26px;
            height: 24px;
            float: right;
            color: @color-grey-dark;
            position: relative;
            bottom: 12px;
            right: -12px;
        }
        h1{
            text-align: center;
        }
        p{
            color: @color-grey-dark;
        }
    }
    .content{
        line-height: 32px;
    }
    .created-at{
        display: inline-block;
    }
    .share-buttons{
        display: inline-block;
        @media (min-width: @screen-md){
            padding-left: 30px;
        }
    }
    .product-single{
        margin: 20px 0;
        h2.name{
            margin-top: 0;
        }
        .price{
            font-size: @font-size-large;
        }
    }

    .ad-pc-news-top{
        margin-top: 20px;
    }
    .ad-mobile-news-top{
        margin: 20px 10px 0;
        img{
            width: 100%;
        }
    }
    .special-relative-product{
        .thumbnail{
            .product-picture{
                padding: 0;
            }
            .caption{
                padding: 10px 10px 0;
                .price{
                    margin-top: 10px;
                }
                .view-more {
                    padding: 15px 0;
                }
            }
        }
    }
}

.special-index{
    .list-summary{
        background-color: #e3e2e2;
        padding: 16px 0;
    }
    .list-item{
        margin: 20px 0 0;
    }
}

.special-content{
    background-color: #fff;
    padding: 1px 20px 5px;
    line-height: 30px;
    h1{
        margin-bottom: 20px;
    }
    img{
        max-width: 100%;
    }
}