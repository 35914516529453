@media (max-width: @screen-xs) {
    .container{
        padding-left: 5px;
        padding-right: 5px;
    }
    .row{
        margin-left: -5px;
        margin-right: -5px;
    }
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12{
        padding-left: 5px;
        padding-right: 5px;
    }
    .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse{
        margin-left: -5px;
        margin-right: -5px;
    }
    .navbar-header{
        text-align: center;
        .logo-outer{
            margin-left: 0 !important;
            a{
                background-position: center;
                height: 38px;
            }
        }
    }

    .block{
        margin-bottom: 10px;
        padding: 10px;
    }

    .site-index{
        .section{
            padding-top: 10px;
            &.focus {
                .focus-text {
                    padding: 10px 0 0 40px;
                }
            }
        }
        .main-ins{
            .col-xs-12{
                margin-bottom: 5px;
            }
        }
        .tech-gadgets-news{
            .col-xs-12{
                margin-bottom: 5px;
            }
        }
    }

    .product-index .product-area .list-products{
        padding-top: 10px;
    }
    .product-view{
        .focus-area{
            .product-asset-left{
                margin-bottom: 10px;
            }
            .asset-link{
                height: auto;
                position: static;
                .asset-link-item{
                    position: static;
                    margin-bottom: 5px;
                    .content{
                        padding-top: 0;
                        height: auto;
                    }
                    img{
                        width: 40px;
                    }
                }
            }
        }
        .product-detail{
            padding: 0 10px;
            img.full-width{
                width: 100%;
            }
        }
        .product-info .product-features ul{
            padding-left: 20px;
        }
        .btn-save-to-email{
            padding: 5px 10px;
            font-size: 12px;
            line-height: 1.5;
            border-radius: 3px;
        }
    }
    .product-section{
        padding: 5px 5px 0;
        margin-top: 10px;
    }
    .product-thumbnail .thumbnail .caption{
        padding: 5px 0 0;
    }

    .collection-view{
        padding-top: 10px;
        .group-thumb{
            margin-top: 0;
            div{
                margin-top: 10px;
            }
        }
        .product-list .thumbnail .buy-area .price {
            text-align: center;
        }
    }

    .page-contact {
        .our-information {
            width: auto;
            margin-left: 0;
            margin-top: 20px;
        }
    }
        
}