.quick-action{
    margin-bottom: 0;
    overflow: hidden;
    height: auto;
    li{
        float: left;
        width: 200px;
    }
}

.user-profile{
    .table{
        margin-bottom: 0;
    }
    dl{
        margin-bottom: 0;
        dd, dt{
            padding: 6px;
        }
        dd{
            margin-bottom: 20px;
            @media (min-width: @screen-md) {
                margin-bottom: 0;
            }
        }
    }
}

.user-address{
    .address-list-area{
        background-color: #fff;
        @media (min-width: @screen-md) {
            background-color: transparent;
            padding: 0;
        }
    }
    .address-items{
        background-color: #fff;
        @media (min-width: @screen-md) {
            width: 848px;
            margin-left: 0;
            padding-top: 15px;
        }
    }
    .address-item-outer{
        margin-bottom: 15px;
        @media (min-width: @screen-md) {
            width: 277px;
            padding-right: 0;
        }
    }
    .address-item{
            width: 100%;
            border: 1px solid @color-grey;
            padding: 10px;
            height: 140px;
            float: left;
            margin: 0;
    }
    .actions{
        background-color: #fff;
        padding: 15px;
        border-radius: 4px;
        margin-bottom: 15px;
    }
}

.user-order{
    .order-item{
        padding: 10px 10px 0 10px;
        border: 1px solid @color-grey-light;
        margin: 0 0 10px;
        height: auto;
        overflow: hidden;
        background-color: #fff;
        .order-header{
            padding: 5px;
            border-bottom: 1px solid @color-grey-light;
            margin-bottom: 10px;
            .message-page-link{
                color: @color-primary;
                .unread-message-count{
                    color: @color-danger;
                }
            }
        }
        .order-body{
            .order-products{
                @media (min-width: @screen-md){
                    width: 520px;
                    float: left;
                }
                .order-product-item{
                    margin-bottom: 10px;
                    height: 120px;
                }
                .product-info{
                    float: left;
                    width: 100%;
                    @media (min-width: @screen-md){
                        width: 400px;
                    }
                    .product-thumb{
                        width: 120px;
                        float: left;
                        margin-right: 10px;
                    }
                }
                .unit-price{
                    float: left;
                    width: 60px;
                }
                .quantity{
                    float: left;
                    width: 60px;
                    text-align: center;
                }
            }
            .order-other{
                .status{
                    float: left;
                    width: 100px;
                    text-align: center;
                }
                .action{
                    float: left;
                    width: 80px;
                    text-align: center;
                }
                .totalPrice{
                    float: left;
                    width: 80px;
                    text-align: center;
                }
            }
            .action{
                margin-bottom: 10px;
                @media (min-width: @screen-md){
                    margin-bottom: 0;
                }
            }
        }
    }
    .order-list-title{
        height: 40px !important;
        font-size: @font-size-small;
        .order-product-item{
            min-height: 16px !important;
            height: auto !important;
        }
    }
    .order-other-mobile{
        dl{
            border-top: 1px solid #F4F4F4;
            margin-top: 4px;
            padding-top: 8px;
            height: 52px;
            margin-bottom: 10px;
        }
        dt {
            float: left;
            clear: left;
            text-align: left;
            margin-bottom: 4px;
        }
        dd {
            float: right;
            clear: right;
            text-align: right;
        }
        .action .btn{
            width: 100%;
        }
    }
}

.user-order-message{
    .leave-message{
        background-color: #fff;
        padding: 18px;
        textarea{
            height: 88px;
        }
        .btn{
            margin-top: 18px;
        }
    }
    .message-list{
        margin-top: 50px;
        ul{
            li{
                margin-bottom: 16px;
                list-style: none;
                .user-name{
                    color:#999;
                    margin-top:26px;
                    display:block;
                    text-align:center;
                    width:50px;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                    -o-text-overflow:ellipsis;
                    overflow: hidden;
                }
                .reply-content-box{
                    .reply-time{
                        color:#e1912d;
                        font-size:12px;
                    }
                    .reply-content{
                        padding: 8px;
                        background-color: #fff;
                        border-radius: 4px;
                        position: relative;
                        .arrow{
                            font-size: 0;
                            border-width: 6px;
                            border-style: dashed dashed dashed solid;
                            position: absolute;
                        }
                    }
                }
                &.even{
                    .user-name{
                        float: right;
                    }

                    .reply-content-box{
                        margin-right:60px;
                        margin-left: 20%;
                        text-align: right;
                        .reply-time{
                            text-align:right;
                        }
                        .arrow{
                            border-color: transparent transparent transparent #fff;
                            right: -12px;
                        }
                    }
                }
                &.odd{
                    .user-name{
                        float: left;
                    }
                    .reply-content-box{
                        margin-left:60px;
                        margin-right: 20%;text-align: left;
                        .reply-time{
                            text-align:left;
                        }
                        .arrow{
                            border-color: transparent #fff transparent transparent;
                            left: -12px;
                        }
                    }
                }
            }
        }
    }
}

.user-sidebar-menu-area{
    padding-top: 20px;
    background-color: #fff;
    .username{
        text-align: center;
    }
    ul{
        margin-top: 10px;
        list-style: none;
        padding-left: 0;
        margin-bottom: 20px;
        li{
            padding: 5px 10px;
            margin: 10px 5px;
            border-left: 2px solid #fff;
            i{
                margin-right: 12px;
            }
            &.active, &:hover{
                background-color: @color-grey-light;
                border-left: 2px solid @color-primary;
            }
            a{
                width: 100%;
                height: 100%;
                display: inline-block;
            }
        }
    }
}
.user-product-review{
    .product-info{
        background-color: white;
        height: auto;
        padding: 5px;
        @media (min-width: @screen-md){
            padding: 15px;
        }
    }
    .product-cover{
        display: inline-block;
        float: left;
        img{
            width: 120px;
        }
    }
    .product-title{
        margin-left: 15px;
        @media (min-width: @screen-md){
            display: inline-block;
            width: 620px;
            min-height: 120px;
            font-size: 22px;
        }
        
        h2.name{
            margin: 0 0 15px 0;
            font-size: 18px;
            @media (min-width: @screen-md){
                font-size: 22px;
            }
        }
    }
    .leave-review{
        margin-top: 15px;
    }
    .review-item{
        background-color: white;
        padding: 15px;
        margin-top: 20px;
        .action{
            margin-top: 6px;
        }
    }
}



.user-sidebar-menu-area{
    h3:first-child{
        margin-top: 0;
    }
    .user-menu{
        list-style: none;
        padding-left: 0;
        li{
            line-height: 28px;
            &:after{
                content: '\00BB';
            }
        }
    }
}

