.collection-index{
    .list-summary{
        background-color: #e3e2e2;
        padding: 16px 0;
    }
    .list-item{
        margin: 20px 0 0;
        .collection-item{
            margin-bottom: 15px;
        }
    }
}

.collection-view{
    padding-top: 20px;
    .focus-cover{
        @media (min-width: @screen-md){
            text-align: center;
            color: white;
            position: relative;
        }
        .collection-title{
            position: absolute;
            bottom: 0;
            background: rgba(0,0,0,0.6);
            padding: 20px;
        }
        img{
            width: 100%;
        }
        p{
            margin-top: 32px;
            color: #cecece;
        }
    }
    .group-thumb{
        margin-top: 20px;
        img{
            width: 100%;
        }
    }
    .product-list{
        margin-top: 20px;
        .row{
            margin-left: -2px;
            margin-right: -2px;
        }
        .col-sm-3, .col-sm-6{
            padding-left: 2px;
            padding-right: 2px;
        }
        .thumbnail{
            margin-bottom: 4px;
            .buy-area {
                position: relative;
                bottom: 6px;
                width: 100%;
                .price {
                    text-align: left;
                    padding: 0 0 0 10px;
                }
                .detail-button {
                    text-align: right;
                    padding: 0 2px 0 0;
                }
            }
        }
        .large-thumbnail .large-image{
            width: 100%;
            @media (max-width: @screen-xs){
                margin-bottom: 4px;
            }
        }
    }

    .pet-kit-page{
        .device{
            .device-title{
                text-align: center;
                font-weight: bold;
                margin: 30px 0 40px 0;
                font-size: 20px;
            }
            .device-content{
                dl{
                    text-align: center;
                    dd{
                        h4{
                            color: #f67d1c;
                            font-weight: bold;
                            font-size: 20px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
        .smart-monitor{
            position: relative;
            .background-image{
                img{
                    width: 100%;
                }
            }
            .monitor-content{
                position: absolute;
                left: 46%;
                top: 15px;
                color: #fff;
                .monitor-title{
                    margin-bottom: 20px;
                }
                span.title{
                    display: block;
                }
                span.content{
                    display: block;
                    position: absolute;
                    left: 25%;
                    top: 0;
                }
                .monitor-brand, .monitor-price{
                    position: relative;
                    width: 200px;
                }
                .add-cart-button{
                    margin: 20px 0;
                }
            }
        }
        .instruction{
            text-align: center;
            padding: 80px 0;
        }
        .pet-product-list{
            @media (max-width: @screen-xs){
                margin-top: 20px;
            }
            .product-wrap{
                margin-bottom: 20px;
                .product-image{
                    img{
                        width: 100%;
                        max-height: 306px;
                    }
                }
                .product-message{
                    position: relative;
                    background: #fff;
                    padding: 15px 15px 50px 15px;
                    h5{
                        font-size: 20px;
                    }
                    .product-name{
                        display: block;
                        margin: 10px 0;
                    }
                    .product-price{
                        color: #f42626;
                        font-size: 26px;
                    }
                    .product-introduction{
                        line-height: 24px;
                    }
                    .go-to-buy{
                        background-color: #f67d1c;
                        float: right;
                    }
                }
            }
        }
    }

}