.site-index{
    text-align: center;

    .section{
        padding-top: 20px;
        img{
            width: 100%;
        }
        &.focus {
            position: relative;
            .focus-text {
                position: absolute;
                text-align: left;
                color: #fff;
                padding: 50px 0 0 80px;
                h2 {
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 120%;
                    font-family: "HCo Gotham SSm","Helvetica Neue",Helvetica,Arial,sans-serif;
                    letter-spacing: -.3px;
                    text-transform: capitalize!important;
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                    color: #fff;
                    margin-bottom: 40px;
                }
            }
        }
    }
    .separate-line{
        border-top: 2px dashed @color-grey-weight;
        width: 80%;
        display: inline-block;
        margin: 24px 0 0;
    }

}