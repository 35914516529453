.shelf-menu{
	padding-left: 0;
    margin-bottom: 0;
    li{
    	list-style: none;
	    display: inline-block;
	    margin: 4px 12px;
    }
}

.category-block{
	position: relative;
	margin-bottom: 10px;
	@media (min-width: @screen-md){
        margin-bottom: 20px;
    }
   	.cover{
    	&:before{
    		left: 5px;
    		right: 5px;
    		content: "";
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6));
		    @media (min-width: @screen-md){
		    	left: 10px;
    			right: 10px;
			}
    	}
    	img{
	   		width: 100%;
	   	}
   	}
   	
	.caption{
		position: absolute;
	    top: 15%;
	    color: white;
	    text-align: center;
	    width: 96%;
	    font-size: 24px;
	    @media (min-width: @screen-md){
	        top: 32%;
	        font-size: 28px;
	    }
	}
}

.category-cover{
	margin-top: 10px;
	@media (min-width: @screen-md){
        margin-top: 20px;
    }
}

.category-item{
	.caption{
	    padding-top: 20px;
		@media (min-width: @screen-md){
	        padding-top: 0;
	    }
	    .title{
			color: white;
			font-size: 26px;
			font-weight: bold;
		}
	}
}