.site-login, .site-signup{
    &>.container{
        padding-top: 40px;
        .login-area, .signup-area{
            overflow: hidden;
            border-radius: 5px;
            padding: 10px 32px 32px;
            background: #FFF;
            box-shadow: 0 9px 50px rgba(0, 0, 0, 0.1);
            margin-top: 0;
            @media (min-width: @screen-md){
                margin-top: 28px;
            }
        }
    }
}

.site-error{
    padding-top: 40px;
    .error-area{
        text-align: center;
        padding-bottom: 30px;
        .error-code{
            font-size: 120px;
        }
    }
}

.site-warning{
    .message{
        min-height: 300px;
        text-align: center;
        padding-top: 120px;
    }
}

.page-contact{
    .address-item{
        margin-bottom: 22px;
    }
}

.third-party-login-divide{
    text-align: center;
    margin: 30px 0 10px;
    .inline{
        background-color: #cecece;
        height: 1px;
        position: relative;
        top: 13px;
        z-index: 1;
    }
    .text{
        display: inline-block;
        background-color: #fff;
        z-index: 2;
        position: relative;
        padding: 0 10px;
        font-size: 16px;
    }
}

.checkout-guest .guest-message{
    margin-top: 10px;
    color: #999;
}

.site-request-password-reset, .site-reset-password{
    padding-top: 30px;
}

.site-message{
    padding-top: 30px;
}

.page-feedback{
    .smcx-widget{
        max-width: 100%;
        height: 1000px;
    }
    .smcx-iframe-container{
        max-width: 100% !important;
        height: 1000px !important;
    }
}

product-wish {
    &.list-wish-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        padding: 7px 6px 5px 7px;
        background-color: rgba(119, 119, 119, 0.2);
        color: white;
        i {
            font-size: 22px;
            cursor: pointer;
        }
    }
    &.remove-my-wish {
        i.remove-wish {
            position: absolute;
            right: 10px;
            top: -8px;
            font-size: 22px;
            color: gray;
            cursor: pointer;
        }
    }
    i.wished {
        color: #FF4545;
    }
    i.changing {
        color: #FF4545;
        font-size: 26px;
        margin-right: -2px;
    }
}

footer{
    background-color: #1A1713;
    border-top: 1px solid #ddd;
    padding-top: 20px;
    color: #FFFFFF;
    a{
        color: #FFFFFF;
    }
    ul.links{
        text-transform: capitalize;
        .gift-shake-gif{
            width: 20px;
            height: 20px;
        }
    }

    .footer-computer{
        padding-bottom: 50px;
        ul.links{
            padding-left: 0;
            li{
                list-style: none;
                margin: 10px 0;
            }
            .gift-shake-gif{
                margin-top: -6px;
                margin-left: 4px;
            }
        }
        .follow-links{
            ul{
                padding-left: 0;
                li{
                    list-style: none;
                    .fa{
                        vertical-align: middle;
                        height: 34px;
                        margin-right: 6px;
                    }
                }
            }
            .footer-links-email-title{
                margin-bottom: 6px;
            }
        }
        .payment-method{
            ul{
                padding-left: 0;
                li{
                    list-style: none;
                    display: inline-block;
                    margin: 10px 10px 10px 0;
                    border: 1px solid white;
                    img{
                        width: 60px;
                        height: 38px;
                    }
                }
            }
        }
    }
}